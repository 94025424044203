import React from "react";
import ReactDom from "react-dom";
import App from "./app"


ReactDom.render(
    // <React.StrictMode>
    //     <MoralisProvider serverUrl={process.env.REACT_APP_MORALIS_SERVER_URL} appId={process.env.REACT_APP_MORALIS_APP_ID}>
    <App />
    //     </MoralisProvider>
    // </React.StrictMode>
    ,
    document.getElementById("root")
);
